var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '../../common/components/Grid';
import PictureTextItem from './PictureTextItem';
var PictureText = function (_a) {
    var kind = _a.kind, items = _a.items;
    return (React.createElement(Grid, null, items.map(function (item, index) { return (React.createElement(PictureTextItem, __assign({ key: index, kind: kind }, item))); })));
};
export default PictureText;
