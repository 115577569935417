var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { width } from 'styled-system';
import { Flex } from '../../common/components/Flex';
import { ResponsiveLazyImage } from '../../common/components/LazyImage';
import { PictureTextKind } from './enums';
var Picture = styled(ResponsiveLazyImage)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
var pictureStyles = { width: 1 };
var getPictureWidth = function (kind) {
    switch (kind) {
        case PictureTextKind.FullWidthPicture:
        case PictureTextKind.DoublePictureText:
            return 1;
        case PictureTextKind.HalfPictureText:
            return [1, 1 / 2];
        default:
            return [1, 2 / 3];
    }
};
var PictureBlock = function (_a) {
    var kind = _a.kind, image = _a.image, href = _a.href;
    var pictureWidth = useMemo(function () { return getPictureWidth(kind); }, [kind]);
    return (React.createElement(Flex, __assign({ width: pictureWidth }, (href && {
        as: 'a',
        href: href,
    })),
        React.createElement(Picture, __assign({}, pictureStyles, image))));
};
export default PictureBlock;
var templateObject_1;
