var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { background, boxShadow, color, space, themeGet, width, } from 'styled-system';
import { ButtonLink } from '../../common/components/Button';
import { Flex } from '../../common/components/Flex';
import RichContent from '../../common/components/RichText/RichContent';
import { PictureTextKind } from './enums';
var Description = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Description.defaultProps = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
};
var RichText = styled(RichContent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), width, space);
RichText.defaultProps = {
    width: 1,
    py: 'sp_8',
};
var CtaLink = styled(ButtonLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:hover,\n  &:visited {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:active,\n  &:hover,\n  &:visited {\n    color: ", ";\n    background: ", ";\n  }\n"])), space, color, background, boxShadow, function (_a) {
    var c = _a.color;
    return c || themeGet('colors.sys.neutral.background.default');
}, function (_a) {
    var bg = _a.background;
    return bg || themeGet('colors.sys.primary.background.default');
});
CtaLink.defaultProps = __assign(__assign({}, ButtonLink.defaultProps), { background: 'sys.primary.background.default', boxShadow: 'none', color: 'sys.neutral.background.default', px: 'sp_16' });
var getContainerWidth = function (kind) {
    switch (kind) {
        case PictureTextKind.DoublePictureText:
        case PictureTextKind.FullWidthPicture:
            return 1;
        case PictureTextKind.HalfPictureText:
            return [1, 1 / 2];
        default:
            return [1, 1 / 3];
    }
};
var TextBlock = function (_a) {
    var _b;
    var kind = _a.kind, revert = _a.revert, description = _a.description, ctaLabel = _a.ctaLabel, ctaUrl = _a.ctaUrl, ctaLabelColor = _a.ctaLabelColor, ctaBackgroundColor = _a.ctaBackgroundColor;
    var hasSpacing = [
        PictureTextKind.DoublePictureText,
        PictureTextKind.FullWidthPicture,
    ].includes(kind);
    var descriptionSpace = (_b = {},
        _b[revert ? 'pr' : 'pl'] = [null, 'sp_24', null, 'sp_32'],
        _b);
    return (React.createElement(Description, __assign({ width: getContainerWidth(kind), justifyContent: hasSpacing ? 'flex-start' : 'center' }, (!hasSpacing && descriptionSpace)),
        React.createElement(RichText, { content: description }),
        ctaUrl && ctaLabel && (React.createElement(CtaLink, { href: ctaUrl, color: ctaLabelColor, background: ctaBackgroundColor }, ctaLabel))));
};
export default TextBlock;
var templateObject_1, templateObject_2, templateObject_3;
